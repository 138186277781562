import React from "react";
import { Fade } from "react-reveal";
import QAccordion from "./accoedion";

const Questions = ({ border, data = [], NoBorder }) => {
  return (
    <>
      <div
        id="faq"
        className={`relative container  py-12 md:py-[98px] lg:py-[118px] 
        ${NoBorder && "!py-12"}
        `}
      >
        <div className="grid lg:grid-cols-3 gap-10 px-2">
          <Fade ssrReveal left delay={350}>
            <div className="col-span-1 ">
              <div className="">
                <h1 className="text-[24px] md:text-[32px] lg:text-[38px] text-center lg:text-start font-medium mb-[16px]">
                  Frequently asked questions
                </h1>
                {!border && (
                  <p className="text-[16px] md:text-[18px] mb-[40px] text-center lg:text-start ">
                    Discover Web3 Domains: FAQs on benefits, ownership, and
                    more.
                  </p>
                )}
              </div>
            </div>
          </Fade>
          <Fade ssrReveal right delay={350}>
            <div className="lg:col-span-2">
              <QAccordion array={data} NoBorderBG={NoBorder} />
            </div>
          </Fade>
        </div>
      </div>
    </>
  );
};
export default Questions;
